<!-- 创建商品 -->
<template>
	<div class="bg-white px-3 goods_create" style="margin: -20px;margin-top: -1rem;margin-bottom: 0!important;">
		<router-link :to="{ name: 'shop_goods_list' }" style="position: absolute;top: 12px;left: 200px;"><el-button size="mini">回到商品列表</el-button></router-link>

		<el-form ref="form" label-width="80px">
			<el-form-item label="壁纸名称" style="padding-top: 9px;">
				<el-input v-model="form.title" placeholder="请输入壁纸名称，不能超过60个字符" class="w-50"></el-input>
			</el-form-item>

			<el-form-item label="缩略图">
				<div>
					<span v-if="!form.cover" class="btn btn-light border mr-2" @click="uploadModel = true"><i class="el-icon-plus"></i></span>
					<img v-else :src="form.cover" class="rounded" style="width: 45px;height: 45px;cursor: pointer;" @click="uploadModel = true" />
				</div>
			</el-form-item>

			<el-form-item label="原图图">
				<div>
					<span v-if="!form.ywj" class="btn btn-light border mr-2" @click="uploadModels = true"><i class="el-icon-plus"></i></span>
					<img v-else :src="form.ywj" class="rounded" style="width: 45px;height: 45px;cursor: pointer;" @click="uploadModels = true" />
				</div>
			</el-form-item>

			<el-form-item label="魔咒"><el-input type="textarea" v-model="form.desc" placeholder="输入魔咒" class="w-50"></el-input></el-form-item>

			<el-form-item label="壁纸分类">
				<el-select v-model="form.category_id" placeholder="请选择壁纸分类">
					<el-option :label="item.name" v-for="(item, index) in cates" :key="index" :value="item.id"></el-option>
				</el-select>
			</el-form-item>

			<el-form-item label="所需积分">
				<el-input type="number" v-model="form.price_jf" class="w-25">
					<template slot="append">
						分
					</template>
				</el-input>
			</el-form-item>

			<el-form-item label="是否上架">
				<el-radio-group v-model="form.status">
					<el-radio :label="0" border>放入仓库</el-radio>
					<el-radio :label="1" border>立即上架</el-radio>
				</el-radio-group>
			</el-form-item>
		</el-form>
		<el-button type="primary" style="position: fixed;bottom: 50px;right: 50px;" @click="submit">提交</el-button>

		<!-- 上传图片 -->
		<el-dialog title="缩略图上传" :visible.sync="uploadModel">
			<div class="text-center">
				<!-- action="/admin/image/upload"  api接口 -->
				<el-upload
					class="upload-demo w-100"
					drag
					action="/admin/image/upload"
					multiple
					:headers="{ token: $store.state.user.token }"
					:data="{ image_class_id: 1 }"
					name="img"
					:on-success="uploadSuccess"
					:on-error="uploadError"
					:before-upload="beforeAvatarUpload"
				>
					<i class="el-icon-upload"></i>
					<div class="el-upload__text">
						将文件拖到此处，或
						<em>点击上传</em>
					</div>
					<div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
				</el-upload>
			</div>
		</el-dialog>

		<el-dialog title="原图上传" :visible.sync="uploadModels">
			<div class="text-center">
				<el-upload
					class="upload-demo w-100"
					drag
					action="/admin/image/upload"
					multiple
					:headers="{ token: $store.state.user.token }"
					:data="{ image_class_id: 2 }"
					name="img"
					:on-success="uploadSuccess"
					:on-error="uploadError"
				>
					<i class="el-icon-upload"></i>
					<div class="el-upload__text">
						将文件拖到此处，或
						<em>点击上传</em>
					</div>
					<div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过6M</div>
				</el-upload>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { mapState } from 'vuex'; // 引入 全局 公共数据
export default {
	inject: ['app', 'layout'],
	data() {
		return {
			uploadModel: false, // 上传图片
			uploadModels: false, // 上传图片
			radio: 1,
			id: 0,
			cates: [],
			form: {
				title: '',
				cover: '',
				ywj: '',
				desc: '',
				ischeck: 1, //是否审核 0待处理 1通过 2拒绝
				status: 1, //是否上架 0仓库 1上架
				price_jf: 1,
				category_id: ''
			}
		};
	},
	computed: {
		// 引入store-modules的user模块里面的user(存储用户相关的数据)
		...mapState({
			user: state => state.user.user,
			navBar: state => state.menu.navBar
		})
	},
	// 过滤器 商品无限级分类
	filters: {
		tree(item) {
			if (item.level == 0) {
				return item.name;
			}
			let str = '';
			for (let i = 0; i < item.level; i++) {
				str += i == 0 ? '|--' : '--';
			}
			return str + ' ' + item.name;
		}
	},
	// 生命周期
	created() {
		// 获取id

		this.id = this.$route.params.id; //拿到当前商品id
		if (this.id) {
			// 获取之前的商品详情
			this.layout.showLoading();
			this.axios
				.get('/admin/goods/read/' + this.id, {
					//获取之前的商品详情 的api
					token: true
				})
				.then(res => {
					let data = res.data.data;
					this.form = data;
					this.layout.hideLoading();
				})
				.catch(err => {
					this.layout.hideLoading();
				});
		}

		// 获取课程分类api
		this.axios
			.get('/admin/goods/create', {
				token: true
			})
			.then(res => {
				let data = res.data.data;
				this.cates = data.cates;
				// this.express = data.express.list
			});
	},
	methods: {
		//提交
		submit() {
			this.layout.showLoading();
			let url = this.id ? '/admin/goods/' + this.id : '/admin/goods';
			if (this.id) {
				delete this.form.create_time;
				delete this.form.update_time;
			}
			this.axios
				.post(url, this.form, {
					token: true
				})
				.then(res => {
					this.$message({
						type: 'success',
						message: this.id ? '修改成功' : '发布成功' //如果this.id存在,就是修改成功,否则就是发布成功
					});
					this.$router.push({
						name: 'shop_goods_list'
					});
					this.layout.hideLoading();
				})
				.catch(err => {
					this.layout.hideLoading();
				});
		},
		// 上传成功 回调
		uploadSuccess(response, file, fileList) {
			if (response.data.image_class_id == 1) {
				this.form.cover = response.data.url;
			} else {
				this.form.ywj = response.data.url;
			}
		},
		uploadError(response, file, fileList) {
			let m = JSON.parse(response.message);
			this.$message.error(m.msg);
		},
		beforeAvatarUpload(file) {
			var _this = this;
			const fileName = file.name;
			const m = fileName.match(/\.(\w+)(#|\?|$)/);
			const fileType = (m && m[1]).toString().toLowerCase();
			const allowHook = ['mp4', 'ogv', 'ogg', 'webm', 'wav', 'mp3', 'ogg', 'acc', 'webm', 'amr', 'doc', 'docx', 'xlsx', 'xls', 'pdf', 'jpg', 'jpeg', 'png', 'gif'];
			const validType = allowHook.includes(fileType);
			if (!validType) {
				const supprtTypes = '视频、文档、Excel、图片';
				this.$message.error('只支持' + supprtTypes + '类型文件上传');
				return false;
			}
			if (fileName.indexOf('%') > -1 || fileName.indexOf('&') > -1) {
				this.$message.error('上传文件名称不能带有字符"%","&"');
				return false;
			}
			if (['jpg', 'jpeg', 'png'].includes(fileType)) {
				return new Promise((resolve, reject) => {
					this.compress(file, newfileex => {
						console.log('newFile', newfileex);
						resolve(newfileex);
					});
				});
			}
		},
		compress(inputFile, callback) {
			const self = this;
			const reader = new FileReader();
			
			reader.readAsDataURL(inputFile);
			reader.onload = function(e) {
				//防止照片文件过大无法上传， 通过以下代码重新创建一个固定宽高的图片再上传
				var image = new Image();
				image.src = this.result; //转化为base64字符串
				self.base64img = image.src;
				image.onload = function() {
					// debugger;
					var expectWidth = this.naturalWidth;
					var expectHeight = this.naturalHeight;
					if (this.naturalWidth > this.naturalHeight && this.naturalWidth > 800) {
						expectWidth = 800;
						expectHeight = (expectWidth * this.naturalHeight) / this.naturalWidth;
					} else if (this.naturalHeight > this.naturalWidth && this.naturalHeight > 1200) {
						expectHeight = 1200;
						expectWidth = (expectHeight * this.naturalWidth) / this.naturalHeight;
					}
					var canvas = document.createElement('canvas');
					var ctx = canvas.getContext('2d');
					canvas.width = expectWidth;
					canvas.height = expectHeight;
					ctx.drawImage(this, 0, 0, expectWidth, expectHeight);
					var dataurl = canvas.toDataURL('image/jpeg', 0.3);
					// 0到1之间的取值，主要用来选定图片的质量，默认值是0.92，超出
					//范围也会选择默认值。
					// callback(dataurl)
					var arr = dataurl.split(','),
						mime = arr[0].match(/:(.*?);/)[1],
						bstr = atob(arr[1]),
						n = bstr.length,
						u8arr = new Uint8Array(n);
					while (n--) {
						u8arr[n] = bstr.charCodeAt(n);
					}
					//修改上传文件名，重新整理进fileList
					var newf = new File([u8arr], inputFile.name, {
						type: mime
					});
					newf.uid = inputFile.uid;
					callback(newf);
				};
			};
		}
	}
};
</script>

<style>
.goods_create .el-form-item {
	margin-bottom: 15px;
}
</style>
